<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    :key="reloadForm"
  >
  <b-col cols="4" class="form-group" >
    <validation-provider
        name="retorno"
        :rules="{ required: false }"
        v-slot="{ errors, touched }"
      >
        <multiselect
        v-if="canEdit"
          v-model="fields.retorno"
          :options="options"
          @search-change="setOptionsRetorno($event)"
          @input="update"
          id="retorno"
          :option-height="40"
          :showLabels="false"
          :searchable="true"
          :internal-search="true"
          :allowEmpty="false"
          :disabled="!canEdit"
          label="name"
          track-by="name"
          placeholder="Digite a data de retorno"
          class="with-border"
          :class="{ error: touched && errors.length > 0 && 1 === 2 }"
        >
          <template slot="caret">
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
          <template slot="singleLabel" slot-scope="{ option }" >
            {{ option.dataRetorno }}
          </template>
          <template slot="noOptions">
            <div>Digite para pesquisar...</div>
          </template>
          <template slot="noResult">
            <div>Nenhuma informação encontrada...</div>
          </template>
        </multiselect>
        <input v-else
          class="form-control"
          autocomplete="off"
          :value="form.fields.retorno"
          :readonly="!canEdit"
        />
      </validation-provider>
  </b-col>  
  </FormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import ChevronDown from '@/assets/icons/chevron-down.svg'

export default {
  components: {
    FormWrapper,
    ChevronDown,
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.retorno,
    })
  },
  data() {
    return {
      fields: this.getDefaultFields(),
      options: [{
        name: '',
        dataRetorno: null
      }],
      reloadForm: 0
    }
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/retorno', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    getDefaultFields() {
      return {
        retorno: null,
      }
    },
    setOptionsRetorno(query) {
      const options = []
      const treatedText = query.replace(/\D/g, '') ? query.replace(/\D/g, '') : '1'

      const Dia = this.moment().add(treatedText, 'd').format('DD/MM/YYYY');
      const Semana = this.moment().add(treatedText , 'w').format('DD/MM/YYYY');
      const Mes = this.moment().add(treatedText, 'M').format('DD/MM/YYYY');
      const Ano = this.moment().add(treatedText, 'y').format('DD/MM/YYYY');

        if (treatedText === '1') {
          options.push(
            {name: '1 Dia', dataRetorno: `${Dia}`},
            {name: '1 Semana', dataRetorno: `${Semana}`},
            {name: '1 Mês', dataRetorno: `${Mes}`},
            {name: '1 Ano', dataRetorno: `${Ano}`},
          )
        } else {
          options.push(
            {name: `${treatedText} Dias`, dataRetorno: `${Dia}`},
            {name: `${treatedText} Semanas`, dataRetorno: `${Semana}`},
            {name: `${treatedText} Meses`, dataRetorno: `${Mes}`},
            {name: `${treatedText} Anos`, dataRetorno: `${Ano}`},
          )
        }
      !options.includes(query) && query && options.push(query);
      this.options = [...options]
    },
     update() {
      this.updateForm('retorno', this.fields.retorno.dataRetorno)
      this.updateMedicalRecord(this.form)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
    }
  }
}
</script>